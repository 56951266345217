import React, { useState, useEffect } from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EnrollmentService from './EnrollmentService';
import OfferedcourseService from '../offeredcourse/OfferedcourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import SyncMoodleService from '../SyncMoodleService';
import MoodleService from '../MoodleService';
import LecturerService from '../lecturer/LecturerService';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ViewEnrollment = () => {
  const {id} = useParams();
  const [header, setHeader] = useState('');
  const [enrolledstudents, setEnrolledstudents] = useState([])
  const [enrolleddata, setEnrolleddata] = useState([]);
  
  useEffect (() => {
    heading(); 
    enrolledstudent();
    // eslint-disable-next-line    
  },[]);

  const heading = async () => {
    OfferedcourseService.getOfferedcourseById(id)
    .then((data) => {
        // console.log('Offered course by id: ',data.data)
        const offeredcourse_data = data.data[0];
        
        //get moodle courseid
        SyncMoodleService.getMoodleCourse(id)
        .then((data) => {
            // console.log(' Sync Moodle course data: ',data.data);
            const moodlecourse_data = data.data;

            MoodleService.getCourseById(moodlecourse_data.courseid)
            .then((data) => {
                // console.log(data.data[0]);
                const moodlecoursedetail_data = data.data[0];

                LearningperiodService.getLearningperiodById(offeredcourse_data.learningperiodid)
                .then((data) => {
                    const learningperiod_data = data.data;
                    // console.log('Learning period data: ',learningperiod_data)
        
                    LecturerService.getLecturerById(offeredcourse_data.lecturerid)
                    .then((data) => {
                        const lecturer_data = data.data;

                        const headerInfo = {
                            'offeredcourseid': offeredcourse_data.id,
                            'learningperiodname': learningperiod_data.name,
                            'learningperioddescription': learningperiod_data.description,
                            'courseid': moodlecourse_data.courseid,
                            'coursefullname': moodlecoursedetail_data.fullname,
                            'coursesummary': moodlecoursedetail_data.summary,
                            'lecturer': lecturer_data.first_name+" "+lecturer_data.middle_name+" "+lecturer_data.last_name                   
                        }
                        // console.log('Header Info: ',headerInfo)
                        setHeader(headerInfo);
                    })                    
                })
            })
        })
    })
  }

  const enrolledstudent = async () => {
    const enrolledlist = await EnrollmentService.getStudentParticipant(id);
    setEnrolledstudents(enrolledlist.data);
    console.log('Enrolled students data: ',enrolledlist.data)
    setEnrolleddata(enrolledlist.data.studentdata);
    console.log('Enrolled students list: ',enrolledlist.data.studentdata)
  }

  const unenroll = async (enrolledstudentId, moodleuserid, courseid) => {
    await Promise.all([
      MoodleService.unenrolCourse(moodleuserid,courseid),
      EnrollmentService.deleteEnrolledstudent(enrolledstudentId)
    ]) 
    
    enrolledstudent();
    let submittedata = {
      'enrolledstudentid': enrolledstudentId,
      'moodleuserid': moodleuserid,
      'courseid': courseid 
    }
    console.log(submittedata)
  }
  
  if (enrolledstudents.enrolledstudent === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <Breadcrumb>
            <Breadcrumb.Item href='/enroll'>Enrolled Students Data</Breadcrumb.Item>
            <Breadcrumb.Item active>Detail Enrolled Students</Breadcrumb.Item>
            <Breadcrumb.Item active>{ id }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Container>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Learning Period :</b></Col>
            <Col xs={9}>{`${header.learningperiodname} (${header.learningperioddescription})`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={9}>{`${header.coursefullname}`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Course Summary :</b></Col>
            <Col xs={9}>{`${header.coursesummary}`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Lecturer :</b></Col>
            <Col xs={9}>{`${header.lecturer}`}</Col>
          </Row>
          <Row><Col>&nbsp;</Col></Row>
          <Row><Col><p className='fs-6 fw-semibold'>There are no enrolled students yet</p></Col></Row>
        </Container>        
      </div>
    )
  }
  else {
    return (      
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <Breadcrumb>
            <Breadcrumb.Item href='/enroll'>Enrolled Students Data</Breadcrumb.Item>
            <Breadcrumb.Item active>Detail Enrolled Students</Breadcrumb.Item>
            <Breadcrumb.Item active>{ id }</Breadcrumb.Item>
          </Breadcrumb>
        </div>        
        <Container>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Learning Period :</b></Col>
            <Col xs={9}>{`${header.learningperiodname} (${header.learningperioddescription})`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={9}>{`${header.coursefullname}`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Course Summary :</b></Col>
            <Col xs={9}>{`${header.coursesummary}`}</Col>
          </Row>
          <Row>
            <Col xs={3} className='align-text-top' align='right'><b>Lecturer :</b></Col>
            <Col xs={9}>{`${header.lecturer}`}</Col>
          </Row>
          <Row><Col>&nbsp;</Col></Row>
          <Row>
            <Col xs={2}>&nbsp;</Col>
            <Col xs={8} align='center'>
              <table className='table align-middle table-responsive table-sm'>
                <thead>
                  <tr>
                    <th>Student ID</th>
                    <th>Name</th>            
                    <th>Actions</th>
                  </tr>
                </thead>            
                <tbody className='table-group-divider'>
                  { enrolleddata.map((enrolled, index) => (
                  <tr key={enrolled.id}>
                      <td>{enrolled.studentid}</td>
                      <td>{`${enrolled.first_name} ${enrolled.middle_name} ${enrolled.last_name}`}</td>
                      <td> 
                        <button className='btn btn-primary btn-sm' onClick={ () => unenroll(enrolled.id, enrolled.moodleuserid, header.courseid) }>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                            <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                          </svg>
                          Un-enroll 
                        </button>                        
                      </td>
                  </tr>
                  ))}
                </tbody>          
              </table>            
              </Col>
              <Col xs={2}>&nbsp;</Col>
            </Row>
          </Container>        
      </div>
    )
  }  
}

export default ViewEnrollment