import HttpSso from "../HttpSso";

class SsoService {
    registerUser(data) {
        return HttpSso.post("/sso/register",data);
    }

    appAuthorization(data) {
        return HttpSso.post("/sso/appauthorization",data);
    }
}

export default new SsoService();