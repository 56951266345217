/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import OfferedcourseService from '../offeredcourse/OfferedcourseService';
import CourseService from '../course/CourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import CategoryService from '../category/CategoryService';
import EnrollmentService from './EnrollmentService';
import SyncMoodleService from '../SyncMoodleService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as BsIcons from 'react-icons/bs';
import MoodleService from '../MoodleService';

const EnrollmentList = () => {
  const [students, setStudents] = useState([]);
  const [studentid, setStudentid] = useState('');

  //untuk modal
  const [offeredcourseid, setOfferedcourseid] = useState('');
  const [coursename, setCoursename] = useState('');
  
  const [lgShow, setLgShow] = useState(false);

  const handleClose = () => setLgShow(false);
  const handleShow = (offeredcourseid, coursename) => {
    setOfferedcourseid(offeredcourseid);
    setCoursename(coursename);
    setLgShow(true);
  }
  const reload=()=>window.location.reload();

  useEffect( () => {
    // getStudents();
    getEnrolledList(); 
  },[]);

  const [data, setData] = useState([]); 

  const getEnrolledList = async () => {
    const offeredCourses = await OfferedcourseService.getActiveOfferedcourse();
    // console.log(offeredCourses?.data);
    const RowData = offeredCourses?.data.map(async({ id,is_active,learningperiodid,courseid,categoryid }) => {
      try {
        const [learningperiodData, courseData, categoryData, enrolledstudentData] = await Promise.all([
          LearningperiodService.getLearningperiodById(learningperiodid),
          CourseService.getCourseById(courseid),
          CategoryService.getCategoryById(categoryid),
          EnrollmentService.getStudentParticipant(id)
        ])

        return {
          id: id,
          // eslint-disable-next-line
          is_active: is_active == 1 ? 'Active' : 'Not Active',
          category: categoryData.data?.name,
          course: courseData?.data?.name_en,
          learningperiod: learningperiodData.data?.name,
          enrolledstudent: enrolledstudentData.data?.enrolledstudent
        }
      } catch (error) {
        console.log(error)
      }
    })
    return await Promise.all(RowData)
      .then((values) => setData(values))
      .catch((error) => console.log(error))
  }

  // console.log(data);
  
  const getStudents = async (offeredcourseid) => {
    // const students = await StudentService.getAll();
    // if (!offeredcourseid) {
      const students = await EnrollmentService.getStudentToEnroll(offeredcourseid)
      setStudents(students.data);
    // }
    // console.log(students.data)
  }
  
  const enrolStudent = async () => {
    SyncMoodleService.getMoodleCourse(offeredcourseid)
    .then((data) => {
      console.log('data course: ',data.data);
      const coursedata = data.data;

      const enrollment_data = {
        'offeredcourseid': offeredcourseid,
        'studentid': studentid
      }
      console.log('enrol student data: ',enrollment_data);
      EnrollmentService.addEnrolledstudent(enrollment_data)
      .then ((data) => {
        console.log('Inserted enrolled student: ',data);

        SyncMoodleService.getUserByStudentid(studentid)
        .then((data) => {
          console.log('data student: ',data.data)
          const moodle_user = data.data;
          const mdl_enrol_data = {
            'enrolments[0][courseid]': coursedata.courseid,
            'enrolments[0][userid]': moodle_user.userid
          }
  
          console.log('manual enrol data: ',mdl_enrol_data)
          MoodleService.enrolCourse(
            mdl_enrol_data['enrolments[0][courseid]'],
            mdl_enrol_data['enrolments[0][userid]'],
            5 //student
          )
          .then((data) => {
            console.log('Error enrollment: ',data);

            setLgShow(false)
          })
      })

      })
      
    })

  }
  

  if (data.length === 0) {
    return( 
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Enrolled Students</h1>
            <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
            </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The offered course data is empty. Please add some new offered courses !!</p></div>
      </div>
    )
  }
  else {
    return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Enrolled Students</h1>
        <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
        </a>
      </div>
      <table className='table table-sm'>
        <thead>
          <tr>
            <th>Learning Period</th>
            <th>Category</th>
            <th>Course Name</th>
            {/*<th align='center'>Time Allocation <br />(minutes)</th>*/}
            <th>Status</th>                       
            <th>Num of Student</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
        { data && data.map(({ id, learningperiod, category, course, is_active, enrolledstudent}) => (
          <tr key={id}>
            <td>{learningperiod}</td>
            <td>{category}</td>
            <td>{course}</td>
            {/*<td>{offeredcourse.duration}</td>*/}
            <td>{is_active}</td>
            <td>{enrolledstudent}</td>
            <td>
              <Link title='Detail' to={`/enroll/`+id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp;
              <Link title='Add Student Participant' onClick={ () => handleShow(id,course) }><BsIcons.BsFillPlusCircleFill size={18} /></Link>
              {/*<Link title='Delete' onClick={()=>deleteOfferedcourse(offeredcourse.id)}><AiIcons.AiTwotoneDelete size={18} /></Link>*/}
            </td>
          </tr>
        ))}
        </tbody>
      </table>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        onExit={reload}
        aria-labelledby="example-modal-sizes-title-lg"
      >        
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Student Participant
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={enrolStudent} onFocus={getStudents(offeredcourseid)}>
        <Modal.Body>          
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Offered Course</label>
              <div className='col-sm-5'>{offeredcourseid}</div>          
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Course Name</label>
              <div className='col-sm-5'>{coursename}</div>          
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Student to Enroll</label>
              <div className='col-sm-5'>
                <select className='form-select' value={ studentid } onChange={ (e) => setStudentid(e.target.value) }>
                  <option>-Select student-</option>
                  {students.map((student, index) => {
                  return (
                  <option key={index} value={student.id}>
                    {`${student.id} ( ${student.first_name} ${student.middle_name} ${student.last_name} )`}
                  </option>
                  )
                })}
                </select>
              </div>
            </div>          
        </Modal.Body>
        <Modal.Footer>
          <div className="field">
            <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
            &nbsp;&nbsp;
            <Link title='Save' className='btn btn-outline-success btn-sm' onClick={enrolStudent}><AiIcons.AiFillSave size={18} />Save</Link>
          </div>
        </Modal.Footer>
        </form>
      </Modal>
    </div>
  )}
}

export default EnrollmentList