import axios from "axios";

// export const API_DEFAULT_PARAMS = {
//     wstoken: 'bde09bc09e2a0ffa06c4fff920b1e1ab',
//     moodlewsrestformat: 'json'
// }

export default axios.create({
  baseURL: "https://vclass.smartcampus.my.id/webservice/rest/server.php",
//   baseURL: "https://learning.smartcampus.my.id/login/token.php",
  params: { 
//     'username': 'smartclass',
//     'password': '5M@rtClass',
//     'service': 'virtual_smartclass', 
    'wstoken': '18f2cd0945dc20ff9e5b2db06cb86e84',
    'moodlewsrestformat': 'json'
  }
});