import HttpCommon from "../HttpCommon";

class SyncMoodleService {
    addUserLecturer(syncData) {
        return HttpCommon.post("/mdluserlecturer",syncData);
    }

    addUserStudent(syncData) {
        return HttpCommon.post("/mdluserstudent",syncData);
    }

    addCourseCategory(syncData) {
        return HttpCommon.post("/mdlcoursecategory",syncData);
    }

    getCourseCategorybyCategoryid(id) {
        return HttpCommon.get("/mdlcoursecategory/category/"+id)
    }

    getUserByLecturerid(id) {
        return HttpCommon.get("/mdluserlecturer/lecturer/"+id)
    }

    addCourseOfferedcourse(syncData) {
        return HttpCommon.post("/mdlcourseofferedcourse",syncData);
    }

    getMoodleCourse (offeredcourseId) {
        return HttpCommon.get("/mdlcourseofferedcourse/offeredcourse/"+offeredcourseId)
    }

    getUserByStudentid(id) {
        return HttpCommon.get("/mdluserstudent/student/"+id)
    }
}

export default new SyncMoodleService();