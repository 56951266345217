/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import OfferedcourseService from './OfferedcourseService';
import CourseService from '../course/CourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import CategoryService from '../category/CategoryService';
// import LecturerService from '../lecturer/LecturerService';
import SyncMoodleService from '../SyncMoodleService';
import MoodleService from '../MoodleService';

const EditOfferedcourse = () => {
  const {id} = useParams();

  const [learningperiod, setLearningperiod] = useState('');
  const [course, setCourse] = useState('');
  const [category, setCategory] = useState('');
  const [moodlecourseid, setMoodlecourseid] = useState('');

  const [learningperiodid, setLearningperiodid] = useState('');
  const [courseid, setCourseid] = useState('');
  const [categoryid, setCategoryid] = useState('');
  const [lecturerid, setLecturerid] = useState('');

  const [duration, setDuration] = useState('');
  const [is_active, setIs_active] = useState('');

  useEffect( () => {
    getOfferedcourseById();
    getMoodleCourse();
  },[]);

  const getOfferedcourseById = async () => {    
    await OfferedcourseService.getOfferedcourseById(id).then(({ data }) => {
      // console.log(data[0])
      setLearningperiodid(data[0].learningperiodid);
      setCategoryid(data[0].categoryid);
      setCourseid(data[0].courseid);
      setDuration(data[0].duration);
      // eslint-disable-next-line
      data[0].is_active == 0 ? setIs_active(false) : setIs_active(true);  
      setLecturerid(data[0].lecturerid)
      
      CourseService.getCourseById(data[0].courseid).then(({ data }) => {
        // console.log(data);
        setCourse(data.name_id);
      });

      LearningperiodService.getLearningperiodById(data[0].learningperiodid).then(({ data }) => {
        // console.log(data);
        setLearningperiod(data.name);
      })

      CategoryService.getCategoryById(data[0].categoryid).then(({ data }) => {
        // console.log(data);
        setCategory(data.name);
      })
    })    
  }

  const getMoodleCourse = async () => {
    const moodlecourse = await SyncMoodleService.getMoodleCourse(id);
    setMoodlecourseid(moodlecourse.data.courseid);
    // console.log(moodlecourse.data)
  }

  const navigate = useNavigate();

  const updateOfferedcourse = async (e) => {
    e.preventDefault();
    
    let visible = 1;
    is_active ? visible = 1 : visible = 0;

    let data = {
      'id': id,
      'learningperiodid': learningperiodid,
      'courseid': courseid,
      'categoryid': categoryid,
      'duration': duration,
      'is_active': visible,
      'lecturerid': lecturerid        
    }
    console.log(data);
    
    let updatecoursedata = {
      'id': moodlecourseid,
      'visible': visible
    }
    console.log(updatecoursedata);

    await MoodleService.updateCourse(moodlecourseid,visible);
    await OfferedcourseService.putOfferedcourse(data,id);
    /*
    if (!is_active) {
      let updatecoursedata = {
        'id': moodlecourseid,
        'visible': is_active
      }
      await MoodleService.updateCourse(moodlecourseid,is_active)

      // console.log(updatecoursedata);
    }
    
    await MoodleService.updateCourse(moodlecourseid,visible)
        
    await OfferedcourseService.putOfferedcourse({
        learningperiodid: learningperiodid,
        courseid: courseid,
        categoryid: categoryid,
        duration: duration,
        lecturerid: lecturerid,
        is_active: is_active
    },id);
    */
    navigate("/offeredcourse")
    
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/offeredcourse'>Offered Course Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Offered Course</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>   
      <form onSubmit={ updateOfferedcourse }>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Learning Period</label>
          <label className="col-sm-8 col-form-label">{learningperiod}</label>
        </div>
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Course Name & Category</label>
          <label className="col-sm-8 col-form-label">{course} in {category}</label>
        </div>
        <input type="hidden" value={ courseid } onChange={ (e) => setCourseid(e.target.value) } />
        <input type="hidden" value={ categoryid } onChange={ (e) => setCategoryid(e.target.value) } />
        <input type="hidden" value={ learningperiodid } onChange={ (e) => setLearningperiodid(e.target.value) } />
        <input type="hidden" value={ lecturerid } onChange={ (e) => setLecturerid(e.target.value) } />
        <input type="hidden" value={ moodlecourseid } onChange={ (e) => setMoodlecourseid(e.target.value) } />
        <div className="row mb-3">
          <label className="col-sm-3 col-form-label">Time Allocation</label>
          <div className='col-sm-1'><input 
            type="text"
            className="form-control"
            value={ duration } 
            onChange={ (e) => setDuration(e.target.value) }
            placeholder="Hours allocation"
          /></div>
          <div className='col-sm-5 col-form-label'><label>minutes</label></div>
        </div>
        <div className="row mb-3">
        <label className="col-sm-10 col-form-label">
          <div className='col-sm-5 form-check form-switch'>
            <input className="form-check-input" 
              type="checkbox"
              role="switch" 
              checked={ is_active }
              onChange={ (e) => setIs_active(e.target.checked) }
              id="flexSwitchCheckChecked" />
            <label className="form-check-label">Activate this offered course</label>
          </div>    
          </label>      
        </div> 
        <div className="field">
          <Link to={`/offeredcourse`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateOfferedcourse}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </form> 
    </div>
  )
}

export default EditOfferedcourse