import React from 'react'

function Sidebar() {
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <li className="sidebar-brand align-items-center justify-content-center">
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-building"></i>
        </div>
        <div className="sidebar-brand-text mx-3">SC-AIS</div>
      </li>
      <li><hr className="sidebar-divider" /></li>            
      <li className="nav-item active">
          <a className="nav-link" href="./"><i className="fas fa-fw fa-home"></i><span>HOME</span></a>
      </li>
      <li><hr className="sidebar-divider" /></li>
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-users"></i><span> USERS</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="/student">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user"></i><span>Students</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/lecturer">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-tag"></i><span>Lecturers</span>
        </a>
      </li>
      <li><hr className="sidebar-divider" /></li>
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-book"></i><span> curriculum</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="/course">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book-dead"></i><span>Courses</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/offeredcourse">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book-open"></i><span>Offered Courses</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/learningperiod">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-calendar-times"></i><span>Learning Period</span>
        </a>
      </li>
      <li><hr className="sidebar-divider" /></li>
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-person-booth"></i><span> learning activities</span></div></li>
      <li className="nav-item">
        <a className="nav-link" href="/enroll">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-plus-circle"></i><span>Enrolled Students</span>
        </a>
      </li>
      {/** 
      <li className="nav-item">
        <a className="nav-link" href="/attendance">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-chart-bar"></i><span>Course Attendance</span>
        </a>
      </li>
      */}
    </ul>
  )
}

export default Sidebar