import HttpCommon from "../../HttpCommon";

class StudentService {
    getAll() {
        return HttpCommon.get("/student");
    }

    getStudentById (studentId) {
        return HttpCommon.get("/student/"+studentId);
    }

    addStudent (student) {
        return HttpCommon.post("/student",student);
    }

    putStudent(student, studentId) {
        return HttpCommon.put("/student/"+studentId, student);
    }

    deleteStudent(studentId) {
        return HttpCommon.delete("/student/"+studentId);
    }
}

export default new StudentService();