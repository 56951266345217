import React from 'react';
import logo from '../assets/smart-campus.png';

function Homepage() {
  return (
    <div className='container-fluid'>
        <img src={logo} className='img-fluid' alt='' />
    </div>      
  )
}

export default Homepage