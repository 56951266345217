/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import StudentService from './StudentService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';

const StudentList = () => {
  const [students, setStudents] = useState([]);

  useEffect( () => {
    getStudents();
  },[]);

  const getStudents = async () => {
    const students = await StudentService.getAll();
    setStudents(students.data);
    console.log(students.data);
  }

  const deleteStudent = async (id) => {
    await StudentService.deleteStudent(id);
    getStudents();
  }

  
  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The student data is empty. Please add some new students !!</p></div>
      </div>
    )    
  } else {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <table className='table table-sm'>
          <thead>
            <tr>
            <th>Student ID</th>
            <th>Full Name</th>
            <th>Affiliation</th>
            <th>Department</th>            
            <th>Actions</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            { students.map((student, index) => (
                <tr key={student.id}>
                    <td>{student.id}</td>
                    <td>{student.first_name+" "+student.middle_name+" "+student.last_name}</td>
                    <td>{student.affiliation}</td>
                    <td>{student.degree+"-"+student.study_program}</td>
                    <td>
                        <Link title='Detail' to={`/student/`+student.id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Edit' to={`/student/edit/`+student.id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Delete' onClick={()=>deleteStudent(student.id)}><AiIcons.AiTwotoneDelete size={18} /></Link>
                    </td>
                </tr>
            )) }
          </tbody>
        </table>
      </div>      
    )
  }
}

export default StudentList