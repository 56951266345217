import React from 'react'

function Footer() {
  return (
    <footer className="sticky-footer bg-white">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
            <span>https://smartcampus.my.id&copy;2022</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer