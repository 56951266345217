import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';

import StudentService from './StudentService';
import SyncMoodleService from '../SyncMoodleService';
import MoodleService from '../MoodleService';
import SsoService from '../SsoService';

const AddStudent = () => {
  const [id, setId] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [middle_name, setMiddle_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [study_program, setStudy_program] = useState('');
  const [degree, setDegree] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState('');

  const navigate = useNavigate();

  const saveStudent = async (e) => {
  e.preventDefault();
  await StudentService.addStudent({
    id: id,
    first_name: first_name,
    middle_name: middle_name,
    last_name: last_name,    
    affiliation: affiliation,
    study_program: study_program,
    degree: degree,
    email: email,
    phone_number: phone_number
  }).then((data) => {
    if (!data.data.error) {
      console.log('inserted student data: ',data.data.messages.data);
      const datastudent = data.data.messages.data;
      
      const username = datastudent.id;
      const firstname = datastudent.first_name+" "+datastudent.middle_name;
      const lastname = datastudent.last_name;
      const email = datastudent.email;

      MoodleService.addUser(username,firstname,lastname,email)
      .then((data) => {
        console.log('inserted moodle user from student data:', data.data[0]);
        
        const userid = data.data[0].id;
        const roleid = 5; //Student

        MoodleService.assignUserRole(roleid, userid)
        .then((data) => {
          console.log('assign role data:', data);

          const syncData = {
            'userid': userid,
            'studentid': username,
            'roleid': 5 //Student
          }
          console.log('syncData: ',syncData);
          SyncMoodleService.addUserStudent(syncData)
          .then((data) => {
            console.log('sync mdluserstudent: ',data);

            const integratorData = {
              'username': username,
              'password': 'Sm@rtClas5'
            }

            SsoService.registerUser(integratorData)
            .then((data) => {
              console.log('system integrator data: ',data);

              const appAuthorizationData = {
                'userid': username,
                'applicationid': 2,
                'role': 'student'
              }

              SsoService.appAuthorization(appAuthorizationData)
              .then((data) => {
                console.log('application authorization data: ',data);

                navigate("/student");
              })
            })            
          })          
        })
      })
    }
  });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/student'>Students Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Student</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <form onSubmit={ saveStudent }>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Student ID</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ id } 
            onChange={ (e) => setId(e.target.value) }
            placeholder="Student ID"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Full Name</label>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ first_name } 
              onChange={ (e) => setFirst_name(e.target.value) }
              placeholder="First Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ middle_name } 
              onChange={ (e) => setMiddle_name(e.target.value) }
              placeholder="Middle Name"
            />
          </div>
          <div className='col-sm-2'>
            <input 
              type="text"
              className="form-control"
              value={ last_name } 
              onChange={ (e) => setLast_name(e.target.value) }
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Affiliation</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ affiliation } 
            onChange={ (e) => setAffiliation(e.target.value) }
            placeholder="Affiliation"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Department</label>
          <div className='col-sm-2'>
            <select className='form-select'  value={ degree } onChange={ (e) => setDegree(e.target.value) }>
              <option>Degree</option>              
              <option value="DI">Diploma I</option>
              <option value="DII">Diploma II</option>
              <option value="DIII">Diploma III</option>
              <option value="DIV">Diploma IV</option>
              <option value="S1">Bachelor</option>
              <option value="S2">Magister</option>
              <option value="S3">Doctoral</option>
            </select>
          </div>
          <div className='col-sm-4'><input 
            type="text"
            className="form-control"
            value={ study_program } 
            onChange={ (e) => setStudy_program(e.target.value) }
            placeholder="Department or Study Program"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Email Address</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ email } 
            onChange={ (e) => setEmail(e.target.value) }
            placeholder="Email"
          /></div>
        </div>
        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Phone Number</label>
          <div className='col-sm-6'><input 
            type="text"
            className="form-control"
            value={ phone_number } 
            onChange={ (e) => setPhone_number(e.target.value) }
            placeholder="Phone Number"
          /></div>
        </div>
        <div className="field">
          <Link to={`/student`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveStudent}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </form>
    </div>
  )
}

export default AddStudent