/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import OfferedcourseService from './OfferedcourseService';
import CourseService from '../course/CourseService';
import LearningperiodService from '../learningperiod/LearningperiodService';
import CategoryService from '../category/CategoryService';
import LecturerService from '../lecturer/LecturerService';
import SyncMoodleService from '../SyncMoodleService';

import * as AiIcons from 'react-icons/ai';
import MoodleService from '../MoodleService';

const OfferedcourseList = () => {
  const [data, setData] = useState([]);

  useEffect( () => {    
    getCourseoffered()
  },[]);

  const getCourseoffered = async () => {
    const offeredCourse = await OfferedcourseService.getAll();

    const RowData = offeredCourse?.data.map( async({ id, duration, is_active, learningperiodid, courseid, lecturerid, categoryid }) => {
      try {
        const [learningperiodData, courseData, lecturerData, categoryData, moodlecourseData] = await Promise.all([
          LearningperiodService.getLearningperiodById(learningperiodid),
          CourseService.getCourseById(courseid),
          LecturerService.getLecturerById(lecturerid),
          CategoryService.getCategoryById(categoryid),
          SyncMoodleService.getMoodleCourse(id)
        ])

        return {
          id: id,
          duration: duration,
          // eslint-disable-next-line
          is_active: is_active == 1 ? 'Active' : 'Not Active',
          category: categoryData.data?.name,
          course: courseData?.data?.name_en,
          learningperiod: learningperiodData.data?.name,
          // lecturer: `${lecturerData?.data.first_name} ${lecturerData?.data.middle_name} ${lecturerData?.data.last_name}`
          
          lecturer: {
            first_name: lecturerData?.data.first_name,
            middle_name:  lecturerData?.data.middle_name,
            last_name: lecturerData?.data.last_name
          },
          moodlecourseid: moodlecourseData?.data.courseid
        }
      } catch(error) {
        console.log(error)
      }
        // setData((prevData) => [...prevData, RowData])
    })
    return await Promise.all(RowData)
      .then((values) => setData(values))
      .catch((error) => console.log(error))
  }

  const deleteOfferedCourse = async(offeredcourseid, moodlecourseid) => {
    console.log(offeredcourseid,'-',moodlecourseid);
    await Promise.all([
      MoodleService.deleteCourse(moodlecourseid),
      OfferedcourseService.deleteOfferedcourse(offeredcourseid)
    ])
    getCourseoffered();
  }

  if (data.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Offered Courses Data</h1>
            <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
            </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The offered course data is empty. Please add some new offered courses !!</p></div>
      </div>
    )
  }
  else {
    return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Offered Courses Data</h1>
        <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-book-open"></i> <span>Add New Offered Course</span>
        </a>
      </div>
      <table className='table table-sm'>
        <thead>
          <tr>
            <th>Learning Period</th>
            <th>Category</th>
            <th>Course Name</th>
            <th align='center'>Time Allocation <br />(minutes)</th>
            <th>Lecturer</th>
            <th>Status</th>                       
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
        { data && data.map(({ id, duration, is_active, learningperiod, category, course, lecturer, moodlecourseid}) => (
          <tr key={id}>
            <td>{learningperiod}</td>
            <td>{category}</td>
            <td>{course}</td>
            <td>{duration}</td>
            <td>{`${lecturer.first_name} ${lecturer.middle_name} ${lecturer.last_name}`}</td>
            <td>{is_active}</td>
            <td>
              <Link title='Edit' to={`/offeredcourse/edit/${id}`}><AiIcons.AiFillEdit size={18} /></Link>
              <Link title='Delete' onClick={()=>deleteOfferedCourse(id, moodlecourseid)}><AiIcons.AiTwotoneDelete size={18} /></Link>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )}
}

export default OfferedcourseList